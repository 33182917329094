





































































































































































import { defineComponent, reactive, ref } from "@vue/composition-api";
import { has } from "lodash";
import { AktivitasKlinis } from "../models/aktivitasKlinis";
import AktivitasKlinisFM from "../models/aktivitasKlinisForm";
import router from "@/router";
import useFormModel from "@/apps/core/modules/useFormModel";
import { SaveContext } from "@/apps/core/models/formModel";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "AktivitasKlinisForm",
  props: { headerText: String },
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    PasienModalForm: () =>
      import("@/apps/perawatan/components/PasienModalForm.vue"),
    PerawatanModalForm: () =>
      import("@/apps/perawatan/components/PerawatanModalForm.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const isCreate = !has(router.currentRoute.params, "id");
    const isPerawatanModalFormActive = ref(false);
    const isPasienModalFormActive = ref(false);
    const newPasien = reactive({
      id: null,
      nama: null,
    } as Record<string, string | null>);
    const form = new AktivitasKlinisFM();
    const paramsId = router.currentRoute.params.id;
    const composition = useFormModel(form, paramsId);
    const aktivitas = composition.instance;

    const setPerawatan = (perawatan?: Record<string, any>) => {
      const obj = aktivitas as AktivitasKlinis;
      obj.perawatan = perawatan ?? null;
      isPerawatanModalFormActive.value = false;
      composition.validate("perawatan");
    };

    const setPasien = (pasien?: Record<string, any>) => {
      Object.assign(newPasien, pasien ?? {});
      isPasienModalFormActive.value = false;
      isPerawatanModalFormActive.value = true;
    };

    const showPasienModel = () => {
      isPerawatanModalFormActive.value = false;
      isPasienModalFormActive.value = true;
    };

    const saveAktivitas = async () => {
      if (isCreate) {
        await composition.formRef.save(SaveContext.Create, false);
        if (aktivitas.id) {
          router.replace({
            name: "aktivitas-detail",
            params: { id: aktivitas.id },
          });
        }
      } else {
        composition.save();
      }
    };
    const formatOption = (opt: Record<string, any>) => {
      const mulai = toUserDateFormat(opt.mulai);
      const hingga = toUserDateFormat(opt.hingga);
      opt.nama = `${opt["sub_divisi"].nama} ${opt.kompetensi}: ${mulai} s/d ${hingga}`;
      return opt;
    };

    return {
      // Data
      aktivitas,
      isCreate,
      isPasienModalFormActive,
      isPerawatanModalFormActive,
      newPasien,

      // Composition
      ...composition,

      // Method
      formatOption,
      saveAktivitas,
      setPasien,
      setPerawatan,
      showPasienModel,
    };
  },
});
