






























































import axios from "axios";
import { defineComponent, onActivated, reactive, watch } from "@vue/composition-api";
import { DialogProgrammatic as Dialog } from "buefy";
import { AktivitasKlinis } from "../models/aktivitasKlinis";
import { listVM } from "../modules/stores/aktivitas";
import APP_CONFIG from "@/apps/core/modules/config";
import { isKonsulen, isResiden } from "@/apps/accounts/modules/store";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";

export default defineComponent({
  name: "AktivitasKlinisList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    AktivitasKlinisListBox: () =>
      import("../components/AktivitasKlinisListBox.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const composition = useInfiniteScrollList(listVM, false);
    const filterButtonConfigs = reactive([
      {
        field: "verifikasi",
        value: "",
        valueMap: {
          "": "Verifikasi",
          Belum: "Belum Verifikasi",
          Ya: "Terverifikasi",
          Tidak: "Ditolak",
        },
      },
      {
        field: "jenisRawat",
        valueMap: {
          "": "Rawat",
          Jalan: "Rawat Jalan",
          Inap: "Rawat Inap",
        },
      },
    ]);
    const action = reactive({
      type: null,
      routeName: null,
      callBack: null,
    } as Record<string, any>);

    const batchUpdateVer = async (
      url: string,
      dataSet = {} as Record<string, any>
    ) => {
      await axios.patch(url, {
        'data_set': dataSet,
      });
    };

    const confirmVerifyAll = () => {
      Dialog.confirm({
        message: "Verifikasi semua aktivitas?",
        onConfirm: () => {
          const aktivitasList = composition.list;
          const aktDiagSet = {} as Record<string, any>;
          for (const akt of aktivitasList) {
            for (const aktDiag of akt.perawatan.diagnosisList) {
              aktDiagSet[aktDiag.id] = { verifikasi: "Ya" };
            }
          }

          const aktKetSet = {} as Record<string, any>;
          for (const akt of aktivitasList) {
            for (const aktKet of akt.aktKeterampilanList) {
              aktKetSet[aktKet.id] = { verifikasi: "Ya" };
            }
          }

          if (aktKetSet !== {}) {
            const verKetUrl = `${APP_CONFIG.baseAPIURL}/aktivitas/keterampilan/verifikasi/`;
            batchUpdateVer(verKetUrl, aktKetSet);
          }

          if (aktDiagSet !== {}) {
            const verDiagUrl = `${APP_CONFIG.baseAPIURL}/aktivitas/diagnosis/verifikasi/`;
            batchUpdateVer(verDiagUrl, aktDiagSet);
          }

          if (aktKetSet !== {} && aktDiagSet !== {}) {
            listVM.reset();
          }
        },
      });
    };

    if (isResiden.value) {
      Object.assign(action, {
        type: "add",
        routeName: "aktivitas-add",
        callBack: null,
      });
    } else {
      watch(
        () => composition.filterMap,
        (newValue) => {
          if (newValue.verifikasi !== "Belum") {
            // reset filterMap
            for (const field of Object.keys(action)) {
              action[field] = null;
            }
            return;
          } else if (isKonsulen.value && newValue.verifikasi === "Belum") {
            Object.assign(action, {
              type: "verifikasi",
              routeName: null,
              callBack: confirmVerifyAll,
            });
          }
        },
        { deep: true }
      );
    }

    const pop = (aktivitas: AktivitasKlinis) => {
      const idx = composition.listVMRef.list.indexOf(aktivitas);
      composition.listVMRef.list.splice(idx, 1);
    };

    onActivated(() => {
      if (listVM.getRefreshRequest() !== "") {
        // ada permintaan refresh list (dengan initial filtering)
        listVM.applyFilter(listVM.refreshRequest.filterMap);
        if (listVM.refreshRequest.filterMap) {
          // reset filtering buttons
          for (let idx = 0; idx < filterButtonConfigs.length; idx++) {
            filterButtonConfigs[idx].value = "";
          }
          const verifikasi = listVM.refreshRequest.filterMap.verifikasi;
          if (verifikasi) filterButtonConfigs[0].value = verifikasi;
        }
        listVM.resetRefreshRequest();
      } else if (listVM.list.length === 0) {
        // ketika listview pertama kali dipanggil, meload data list
        // asumsinya kalau list kosong, perlu fetch data dulu
        composition.onPageChange();
      }
    });

    return {
      // Data
      action,
      filterButtonConfigs,
      isResiden,

      // Composition
      ...composition,
      ...useListFilter(),

      // Method
      pop,
    };
  },
});
