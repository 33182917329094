import accountStateRef from "@/apps/accounts/modules/store";
import FormModel from "@/apps/core/models/formModel";
import { setRefreshRequest } from "../modules/stores/aktivitas";
import { AktivitasKlinis, AktivitasKlinisAPI } from "./aktivitasKlinis";

export default class AktivitasKlinisFM extends FormModel {
  constructor() {
    const requiredFields = ["tanggal", "perawatan", "lokasi", "dpjp"];
    super(
      new AktivitasKlinisAPI(),
      new AktivitasKlinis(),
      requiredFields,
      ["stase", "catatan"],
      setRefreshRequest
    );
  }

  getPayload() {
    const payload = super.getPayload();
    payload.residen = accountStateRef.me.anggota?.residen.id;
    // if (payload.catatan === null) payload.catatan = "";
    return payload;
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}
