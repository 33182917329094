


























































































import { computed, defineComponent } from "@vue/composition-api";
import { DialogProgrammatic as Dialog } from "buefy";
import Vue from "vue";
import AktivitasVM from "../models/aktivitasKlinis";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useViewModel from "@/apps/core/modules/useViewModel";
import { setRefreshRequest as setAktivitasRefreshRequest } from "@/apps/aktivitas/modules/stores/aktivitas";
import { setRefreshRequest as setPerawatanRefreshRequest } from "@/apps/perawatan/modules/stores/perawatan";

const setRefreshRequest = (
  requestBy: string,
  filterMap?: Record<string, string>
) => {
  setAktivitasRefreshRequest(requestBy, filterMap);
  setPerawatanRefreshRequest(requestBy, filterMap);
};

export default defineComponent({
  name: "AktivitasKlinisDetail",
  components: {
    AktivitasKomponenContainer: () =>
      import("@/apps/aktivitas/components/AktivitasKomponenContainer.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    LulusTag: () => import("@/apps/core/components/LulusTag.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const aktivitasVM = new AktivitasVM();
    const { viewModelRef, instance } = useViewModel(paramsId, aktivitasVM);
    const menuConfigs = computed(() => {
      if (instance.extraData?.canChange) {
        return [
          {
            label: "Ubah",
            onClick: () => router.push({ name: "aktivitas-change" }),
          },
          {
            label: "Hapus",
            onClick: () => {
              Dialog.confirm({
                message: "Hapus aktivitas ini?",
                onConfirm: async () => {
                  if (!instance.id) return;
                  await aktivitasVM.api.delete(instance.id);
                  setRefreshRequest("context-menu");
                  router.go(-1);
                },
              });
            },
          },
        ];
      }
      return [];
    });

    const showImages = (fotoList: Array<Record<string, string>>) => {
      const items: Array<Record<string, string>> = [];
      fotoList.forEach((foto: Record<string, string>) => {
        items.push({ src: foto.url });
      });
      Vue.prototype.$Pswp.open({
        items: items,
      });
    };

    return {
      // Data
      aktivitas: instance,
      menuConfigs,
      viewModelRef,

      // Method
      toUserDateFormat,
      showImages,
    };
  },
});
